import React, { useRef } from "react";
import Page from "../components/Page";
import BlogCard from "../components/Blog/BlogCardSimple";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import "../components/Produktivitet/produktivitet.scss"

export default function Produktivitet({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const ref = useRef(null);

  return (
    <>
      <Page
        title="Bli mer produktiv | Framgångspillret"
        description="Få tips och tricks om hur du kan bli mer produktiv och får fler saker gjort, både i studier och i arbetet."
      >
          <div className="container px-5 py-6 mx-auto">
            <div className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="produktivitet" />}
              <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">Hur blir du mer produktiv?</h1>
              <p>
                Produktivitet handlar om att använda sin tid och energi på ett
                <b> bättre och mer effektivt sätt</b>. Detta kan vi göra på flera olika
                sätt men ofta innefattar det att använda sig av olika tekniker
                och verktyg som effektiviserar vårt arbete och gör att vi lär oss snabbare.
              </p>
              <p>
                <b>Varför skall vi bry oss om produktivitet?</b> Vi skall bry om
                produktivitet eftersom vår tid är otroligt värdefull. Genom att
                också bli mer effektiva kommer det leda till att vi får gjort
                det vi måste göra snabbare, vilket ger oss mer tid att spendera
                med våra nära och kära, slappna av och ta hand om oss själva.
              </p>
              <h2>Hur kan man bli mer produktiv?</h2>
              <p>Det finns många saker som vi kan göra för att bli mer produktiva. Vi kan automatisera processer, planera mer effektivt och prioritera bättre. </p>
              <p>Planering är en viktigt del inom att få mer saker gjort, både på arbetet eller på fritiden. Genom att planera får vi en bättre uppfattning på vad vi skall göra och kan därmed använda vår tid bättre. Det är också bra om vi använder verktyg såsom Eisenhowers matris för att prioritera våra uppgifter. Eisenhowers matris bygger att man prioriterar uppgifter efter hur viktiga och brådskande de är. Det är viktigt att vi alltid börjar göra de uppgifterna som både är viktiga och brådskande.</p>
              <p>Det är också viktigt att vi försöker eliminera all distraktioner eftersom de annars kommer att störa oss i vårt arbete och kan påverka vår effektivitet på ett negativt sätt. Detta kan vi göra genom att lägga undan vår mobiltelefon och stänga ner alla program och flikar som vi inte kommer använda om vi ska arbeta framför datorn.</p>
              <p>Om vi vill blir mer produktiva är det också viktigt att vi tar pauser och prioriterar vår återhämtning, för det är så som vi återför vår energi igen. Och den är viktig för att vi ska kunna prestera. Det är också viktigt att vi prioriterar vår sömn, eftersom det kommer leda till att vi kommer ta smartare beslut och vara piggare.</p>
              <h2>Verktyg för ökad produktivitet</h2>
              <p>Det finns massor av verktyg och tekniker som kan hjälpa en att bli mer produktiv. Tre tekniker som är mycket vanliga för att öka din produktivitet är timeboxing, pomodoro-tekniken och todolistor.</p>
              <h3>1. Timeboxing</h3>
              <p>Timeboxing en tidsplanerings metod som kan hjälpa dig att öka din produktivitet där du allokerar ett fast tidspann av tid för en aktivitet i förväg, du genomför sedan aktiviteten inom det tidsspannet. Du slutar alltså arbeta med aktiviteten när tiden är slut, och därefter sedan du om du har nått dina planerade mål.</p>
              <Link to={"/verktyg/timeboxing/"} aria-label="timeboxing">
                <button className="showBtnProductivity"><span>Läs mer</span></button>
              </Link>
              <h3>2. Pomodoro-tekniken</h3>
              <p>Pomodoro-tekniken är kanske en teknik för ökad produktivitet som du hört talas om tidigare. Oavsett om du hört om den eller inte tidigare så innebär Pomodoro att man arbetar fokuserat på en uppgift i 25 minuter utan distraktioner, följt av en paus på 5 minuter. För att därefter upprepas igen. Efter fyra Pomodoro's bör du därefter ta en lite längre paus på minst 15-30 minuter innan ut återgår till arbetet igen.</p>
              <Link to={"/verktyg/pomodorotekniken/"} aria-label="pomodoro">
                <button className="showBtnProductivity"><span>Läs mer</span></button>
              </Link>
              <h3>3. Todolistor</h3>
              <p>En todolist eller en att-göra-lista har du säkert både använt och hört om tidigare. Den är helt klart en klassiker och även något jag brukar använda mig av i min vardag. Denna att-göra-listan är mycket enkel och sparar alla dina uppgifter i din webbläsare om du vill komma tillbaka till dem senare igen.</p>
              <Link to={"/verktyg/todolist/"} aria-label="todolist">
                <button className="showBtnProductivity"><span>Läs mer</span></button>
              </Link>
              <br />
              <br />

              <h2>Se även</h2>

              <hr />
              <BlogCard
                title={"Studier"}
                image={data.study.childImageSharp.gatsbyImageData}
                altImage={"plugg studier"}
                link={"/produktivitet/studier/"}
                text={
                  "Få tips om hur du kan bli bättre i studier och lär dig olika studietekniker och metoder för att snabba upp inlärning. Du får dig också lära dig mer om hur vi lär oss."
                }
              />
            </div>
          
            <br />
            <br />
        </div>
      </Page>
    </>
  );
}

export const query = graphql`
  query {
    tools: file(relativePath: { eq: "framgangspillret.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    study: file(relativePath: { eq: "study.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image: file(relativePath: { eq: "productiveImage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
