import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function LargeBlogCard({ title, author, date, image, text, link }) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <Link to={link} className="blogCard">
        <div class="bg-white shadow-md border border-gray-200 rounded-lg mb-2 h-full">
          <GatsbyImage image={image} className="w-full" />
          <div class="p-5">
            <h5 class="text-gray-900 font-bold text-3xl tracking-tight mb-2">{title}</h5>
            <p class="font-normal text-gray-700 mb-3">{text?.length > 155 ? text.substring(0, 155) + "..." : text}</p>
            <Link to={link} class="text-white bg-emerald-600 hover:bg-emerald-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 mt-3 text-center inline-flex items-center" href="#">
              Läs mer →
            </Link>
          </div>
        </div>
    </Link>

  );
}
